// Generated by Framer (76f10ba)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["OjQxulbQp", "FgGEDtbLk", "D79wklz7E"];

const variantClassNames = {OjQxulbQp: "framer-v-1ni7426", FgGEDtbLk: "framer-v-1x1by4", D79wklz7E: "framer-v-10ladyd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "OjQxulbQp", Tablet: "FgGEDtbLk", Phone: "D79wklz7E"};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "OjQxulbQp", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "OjQxulbQp", variant, transitions, variantClassNames, cycleOrder})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xrnQN", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1ni7426", className)} style={{...style}} layoutId={"OjQxulbQp"} transition={transition} layoutDependency={layoutDependency} background={null} data-framer-name={"Desktop"} ref={ref} {...addPropertyOverrides({FgGEDtbLk: {"data-framer-name": "Tablet"}, D79wklz7E: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><motion.div className={"framer-7d2ldf"} style={{backgroundColor: "rgb(255, 255, 255)"}} layoutId={"cG1brvo3U"} transition={transition} layoutDependency={layoutDependency}><Text style={{"--framer-font-family": "\"Syne\", serif", "--framer-font-style": "normal", "--framer-font-weight": 700, "--framer-text-color": "rgb(0, 0, 0)", "--framer-font-size": "30px", "--framer-letter-spacing": "17.14px", "--framer-text-transform": "none", "--framer-text-decoration": "none", "--framer-line-height": "100%", "--framer-text-alignment": "left"}} withExternalLayout={true} verticalAlignment={"top"} __fromCanvasComponent={true} alignment={"left"} fonts={["GF;Syne-700"]} className={"framer-1ew0d34"} layoutId={"DtG3Cnq7f"} transition={transition} layoutDependency={layoutDependency} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>RAIN</span><br></span></span>"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xrnQN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xrnQN * { box-sizing: border-box; }", ".framer-xrnQN .framer-1ni7426 { position: relative; width: 1280px; height: 100px; display: flex; flex-direction: row; justify-content: center; align-content: center; align-items: center; flex-wrap: nowrap; gap: 0px; padding: 0px 0px 0px 0px; }", ".framer-xrnQN .framer-7d2ldf { position: relative; z-index: 1; max-width: 1280px; width: 1px; height: 100px; flex: 1 0 0px; display: flex; flex-direction: row; justify-content: flex-start; align-content: center; align-items: center; flex-wrap: nowrap; gap: 10px; padding: 0px 50px 0px 50px; }", ".framer-xrnQN .framer-1ew0d34 { position: relative; overflow: visible; width: auto; height: auto; flex: none; white-space: pre; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xrnQN framer-1ni7426 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-xrnQN framer-1ni7426 > :first-child, .framer-xrnQN framer-7d2ldf > :first-child { margin-left: 0px; } .framer-xrnQN framer-1ni7426 > :last-child, .framer-xrnQN framer-7d2ldf > :last-child { margin-right: 0px; } .framer-xrnQN framer-7d2ldf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ".framer-xrnQN.framer-v-1x1by4 .framer-7d2ldf { padding: 0px 30px 0px 30px; }", ".framer-xrnQN.framer-v-10ladyd .framer-7d2ldf { padding: 0px 20px 0px 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FgGEDtbLk":{"layout":["fixed","fixed"]},"D79wklz7E":{"layout":["fixed","fixed"]}}}
 */
const FramerLjZ5zyg76: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerLjZ5zyg76;

FramerLjZ5zyg76.displayName = "Menu Desktop";

FramerLjZ5zyg76.defaultProps = {width: 1280, height: 100};

addPropertyControls(FramerLjZ5zyg76, {variant: {type: ControlType.Enum, title: "Variant", options: ["OjQxulbQp", "FgGEDtbLk", "D79wklz7E"], optionTitles: ["Desktop", "Tablet", "Phone"]}} as any)

addFonts(FramerLjZ5zyg76, [{url: "https://fonts.gstatic.com/s/syne/v14/8vIS7w4qzmVxsWxjBZRjr0FKM_3fvj6kR47NCV5Z.ttf", family: "Syne", style: "normal", weight: "700", moduleAsset: {url: "https://fonts.gstatic.com/s/syne/v14/8vIS7w4qzmVxsWxjBZRjr0FKM_3fvj6kR47NCV5Z.ttf", localModuleIdentifier: "local-module:canvasComponent/LjZ5zyg76:default"}}])